// src/components/Puzzle.js
import React, { useState, useEffect } from 'react';
import './Puzzle.css';

const Puzzle = ({ image, onSolved }) => {
  const [pieces, setPieces] = useState([]);
  const [shuffled, setShuffled] = useState([]);
  const [draggedPiece, setDraggedPiece] = useState(null);

  useEffect(() => {
    const imgPieces = [];
    const size = 3; // 3x3 puzzle
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        imgPieces.push({ x, y, order: y * size + x });
      }
    }
    setPieces(imgPieces);
    setShuffled(shuffleArray([...imgPieces]));
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleDragStart = (index) => {
    setDraggedPiece(index);
  };

  const handleDrop = (index) => {
    if (draggedPiece !== null) {
      const newShuffled = [...shuffled];
      [newShuffled[index], newShuffled[draggedPiece]] = [newShuffled[draggedPiece], newShuffled[index]];
      setShuffled(newShuffled);
      setDraggedPiece(null);

      if (isSolved(newShuffled)) {
        onSolved();
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTouchStart = (e, index) => {
    e.preventDefault();
    setDraggedPiece(index);
  };

  const handleTouchEnd = (e, index) => {
    e.preventDefault();
    handleDrop(index);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const isSolved = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].order !== i) return false;
    }
    return true;
  };

  return (
    <div className="puzzle-container">
      {shuffled.map((piece, index) => (
        <div
          key={index}
          className="puzzle-piece"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: `${-piece.x * 100}% ${-piece.y * 100}%`,
          }}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDrop={() => handleDrop(index)}
          onDragOver={handleDragOver}
          onTouchStart={(e) => handleTouchStart(e, index)}
          onTouchEnd={(e) => handleTouchEnd(e, index)}
          onTouchMove={handleTouchMove}
        />
      ))}
    </div>
  );
};

export default Puzzle;
