// src/App.js
import React, { useState } from 'react';
import Puzzle from './components/Puzzle';
import ProtectedContent from './components/ProtectedContent';
import './App.css'; // Create this CSS file for general styles

const App = () => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isSolved, setIsSolved] = useState(false);
  const [password, setPassword] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'bandymas') {
      setIsPasswordCorrect(true);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className="App">
      {!isPasswordCorrect ? (
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      ) : isSolved ? (
        <ProtectedContent />
      ) : (
        <Puzzle image={`${process.env.PUBLIC_URL}/image.jpg`} onSolved={() => setIsSolved(true)} />
      )}
    </div>
  );
};

export default App;
